import * as amplitude from "@amplitude/analytics-browser";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

import api from "@/api/axios";
import bgImage from "@/assets/backgrounds/background.svg";
import Logo from "@/components/Logo";
import { useChord } from "@/hooks/useChord";
import { usePageContext } from "vike-react/usePageContext";
import { useUser } from "@/hooks/useUser";

import DrawerContent from "@/layouts/dashboard/DrawerContent";
import TopNav from "@/layouts/dashboard/TopNav";

import type { CurrentUser } from "@/types/user";

export default function LayoutProtected({ children }: { children: React.ReactNode }) {
  const chord = useChord();

  const pageContext = usePageContext();
  const { urlPathname } = pageContext;

  const { setUser } = useUser();

  useEffect(() => {
    amplitude.init(import.meta.env.VITE_AMPLITUDE_KEY, {
      autocapture: {
        attribution: true,
        pageViews: true,
        sessions: true,
        formInteractions: true,
        fileDownloads: true,
        elementInteractions: true,
      },
    });
  }, []);

  useEffect(() => {
    chord.page();
  }, [chord, urlPathname]);

  const { data: currentUser } = useQuery({
    queryKey: ["current-user"],
    queryFn: async () => {
      const { data } = await api.get<CurrentUser>("/v1/current_user");
      amplitude.setUserId(data.id);
      setUser(data);
      return data;
    },
    retry: false,
  });

  const isCheckout = urlPathname === "/checkout";

  if (!currentUser) {
    if (isCheckout) {
      return (
        <div className="bg-white">
          <div className="navbar bg-primary text-primary-content w-full">
            <div className="navbar-start"></div>
            <div className="navbar-center text-primary-content">
              <Logo />
            </div>
            <div className="navbar-end">
              <div className="w-10" />
            </div>
          </div>
          {children}
        </div>
      );
    }

    return children;
  }

  if (isCheckout) {
    return (
      <div className="bg-white">
        <div className="navbar bg-primary text-primary-content w-full">
          <div className="navbar-start">
            <button onClick={() => window.history.back()} className="btn btn-ghost btn-circle">
              <ArrowLeftIcon className="h-5 w-5" />
            </button>
          </div>
          <div className="navbar-center text-primary-content">
            <Logo />
          </div>
          <div className="navbar-end">
            <div className="w-10" />
          </div>
        </div>
        {children}
      </div>
    );
  }

  const closeDrawer = () => {
    const drawer = document.getElementById("layout-drawer") as HTMLInputElement;
    if (drawer) drawer.checked = false;
  };

  return (
    <>
      {/* Background - Moved to top level */}
      <div
        className="fixed inset-0 pointer-events-none"
        style={{
          backgroundImage: `linear-gradient(oklch(var(--b1) / 0.9), oklch(var(--b1) / 0.9)), url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />

      {/* Top nav - Moved outside the drawer */}
      <TopNav />

      {/* Main Content */}
      <div className="drawer lg:drawer-open scroll-y">
        <input id="layout-drawer" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content flex flex-col lg:ml-80">{children}</div>

        {/* Drawer */}
        <DrawerContent user={currentUser} closeDrawer={closeDrawer} />
      </div>
    </>
  );
}
